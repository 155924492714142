<template>
  <div>
    <div class="w-title" style="margin-bottom: 5px;">

      <nav class="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link to="/">Accueil</router-link>
          </li>
          <li>
            <router-link to="/sitemap">Plan du site</router-link>
          </li>
        </ul>
      </nav>

      <h1 class="title">Plan du site</h1>
    </div>

    <div class="container content" style="text-align: left; margin: 20px">
      <ul>
        <li v-for="(link, index) in links" :key="index">
          <router-link :to="link.routePath">{{link.name}}</router-link> ({{link.path}})
        </li>
      </ul>

    </div>
  </div>

</template>

<script>
export default {
  name: "SiteMap",
  data() {
    return {
      links: []
    }
  },
  created() {
    this.links = this.getRoutesList(this.$router.getRoutes(), 'https://catalogue.fanabriques.fr')
  },

  methods: {
    getRoutesList(routes, pre) {
      return routes.reduce((array, route) => {
        const path = `${pre}${route.path}`;

        if (route.path !== '*') {
          array.push({name: route.name, path: path, routePath: route.path});
        }

        if (route.children) {
          array.push(...this.getRoutesList(route.children, `${path}/`));
        }

        return array;
      }, []);
    }
  }
}
</script>

<style scoped>

</style>